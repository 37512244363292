
import QRCode from 'react-qr-code';
import { CustomTable } from '../../../components/CustomTable/CustomTableFunction';
import { FlexTable } from '../../../components/flexTable/FlexTable';
import * as security_utils from '../../../utils/security-utils';
import React from 'react'

export class ReceiptDetailToPrint extends React.Component {
    render() {
        const date = new Date(this.props.CreateTime * 1000);
        let dateFormat = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();;
        if (this.props.receiptDetail.NoReceiptDate) {
            dateFormat = '.......................'
        }
        const original = this.props.original ? this.props.original : false;
        console.log("Render ToPrint");
        console.log(this.props.receiptDetail.ReceiptAddress)
        return (
            <div className='receipe'>
                <div className="receipt-wrapper">
                    <div className="receipe-header">
                        <div className="shop-detail bold">
                            ชื่อร้าน ATROBOT ที่อยู่ 27 พัฒนาการ 57/1 ถ.พัฒนาการ เขตประเวศ แขวงประเวศ กรุงเทพมหานคร 10250 โทร 083-976-3167
                        </div>
                        <div className="receipt-title">
                            {/* /ใบกำกับภาษี */}
                            {/* /TAX INVOICE */}
                            {
                                this.props.receiptDetail.OrderStatus === 1 ?
                                    <>
                                        {
                                            this.props.is_quotation ?
                                                <>
                                                    <span>ใบเสนอราคา</span>
                                                    <span>QUOTATION</span>
                                                </> :
                                                <>
                                                    <span>ใบแจ้งหนี้</span>
                                                    <span>INVOICE</span>
                                                </>
                                        }
                                    </> :
                                    <>
                                        {original ? <>
                                            <span>ใบเสร็จรับเงิน(ต้นฉบับ)</span>
                                            <span>RECEIPT(ORIGINAL)</span>
                                        </>
                                            :
                                            <>
                                                <span>ใบเสร็จรับเงิน(สำเนา)</span>
                                                <span>RECEIPT(COPY)</span>
                                            </>
                                        }
                                    </>
                            }
                        </div>
                        <div className="invoice-stamp">
                            <span>เลขที่ No {this.props.receiptDetail.OrderID}</span>
                            <span>วันที่ {dateFormat} </span>
                        </div>
                    </div>
                    <div className="receipt-content">
                        <div className="customer-detail">
                            <span>ชือ {this.props.receiptDetail.ReceiptAddress.Name}</span>
                            <span>ที่อยู่ {this.props.receiptDetail.ReceiptAddress.Address}</span>
                            <span>เลขประจำตัวผู้เสียภาษี {this.props.receiptDetail.ReceiptAddress.TaxID}</span>
                        </div>
                        <div className="order-item-list">
                            <CustomTable columns={this.props.tableHeader} data={this.props.tableData} />
                        </div>
                    </div>
                    {
                        this.props.receiptDetail.OrderStatus !== 1 &&
                        <div className="receipt-footer">
                            {this.props.signature ? <div className="sign-area bold">
                                <span>ผู้รับเงิน ธรรมเชษฐ์ อัศวทองกุล</span>
                                <span className='sign-space'></span>
                                <span>ลายเซ็น ...........................................</span>

                            </div>
                                :
                                <div className="sign-area bold">
                                    <span>รับรองโดย ระบบออกบิลอัตโนมัติ erp.atrobot</span><span>วันที่ {dateFormat}</span>
                                    <QRCode value={'www.atrobot.asia/receipt/check/' +
                                        security_utils.encrypt(
                                            this.props.orderSN + "|" + this.props.receiptDetail.OrderID + "|" + this.props.CreateTime
                                        )} size={128} />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
};
