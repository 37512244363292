import React, { useEffect } from 'react'
import NavBar from '../../../../components/navBar/NavBar'
import './BlogDetail.scss'
import * as BlogsApi from '../../../../utils/API/blogs-api'
import ContentEditable from 'react-contenteditable'
import { redirect_back } from '../../../../globalMethods'


export default function BlogDetail() {
    const [blogDetail, setBlogDetail] = React.useState(null)
    // Get blog id from url
    const blogId = window.location.pathname.split('/')[3]
    console.log(blogId)
    useEffect(() => {
        // Get blog detail from server
        BlogsApi.getBlogDetail(blogId).then(res => {
            const adjustedBlogDetail = adjusted_blog_detail(res.data.blog)
            console.log(adjustedBlogDetail)
            setBlogDetail(adjustedBlogDetail)
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }, [])
    const adjusted_blog_detail = (blogDetail) => {
        let content = blogDetail.content
        // Find all <img> tags
        let img_tags = content.match(/<img[^>]*>/g)
        if (img_tags) {
            img_tags.forEach(img_tag => {
                // Get src from img tag
                let src = img_tag.match(/src="[^"]*"/g)[0].replace('src="', '').replace('"', '')
                console.log(src)
                const target_server = ['localhost','api1backend.atrobot.asia/api2']

                //  if src contains target_server
                target_server.forEach(server => {
                    if (src.includes(server)) {
                        console.log('found')
                        // if src not contains size
                        if (!src.includes('size')) {
                            console.log('not found')
                            // add size
                            src = src + '&size=150'
                            console.log(src)
                            // replace src
                            content = content.replace(img_tag, img_tag.replace(/src="[^"]*"/g, `src="${src}"`))

                        }
                    }
                }
                )
            })
        }
        blogDetail.content = content
        return blogDetail
    }

    return (
        <div className='blogdetail'>
            <NavBar name="Blogs" />
            <div className="blogdetail_content">
                {
                    blogDetail && <div className="blogdetail_content_detail">
                        <div className="title">
                            <i class="fa-solid fa-angle-left back_arrow btn-animation"
                            onClick={() => { redirect_back()}}
                            ></i>
                            {blogDetail.title}
                        </div>
                        <div className="body">
                            <ContentEditable html={blogDetail.content} disabled={true} className="blog_html" />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
