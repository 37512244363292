import React, { useEffect, useMemo, useRef } from 'react';
import { WholesaleTableHeaders } from './columns';
import NavBar from '../../components/navBar/NavBar';
import './Wholesale.scss';
import {
    getProductImage,
    getWholeSaleProduct,
} from '../../utils/atrobot-api';
import Spinner from '../../components/spinner/Spinner';
import { FlexTable } from '../../components/flexTable/FlexTable';
import useState from 'react-usestateref';
import * as stats_api from '../../utils/API/stats-api';
import '../../scss/font.scss';
import BlinkingUnderLineNumber from '../../components/BlinkingUnderLineNumber/BlinkingUnderLineNumber';

export default function Wholesale() {
    const wholesaleTableHeaders = useMemo(() => WholesaleTableHeaders, []);
    const [showWholesaleProductData, setShowWholeSaleProductData, showWholesaleProductDataRef] = useState([]);
    const originalWholesaleProductData = useRef([]);
    const [onLoad, setOnLoad] = React.useState(true);
    const [isFetching, setIsFetching] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const config = useRef({
        'limit': 20,
        'offset': 0,
        'keyword': ''
    });
    const PAGE_NAME = 'Wholesale';

    useEffect(
        () => {
            getWholeSaleProduct(config.current.offset, config.current.limit).then(res => {
                prepareProduct(config.current.offset, res.data).then(res => {
                    setOnLoad(false)
                    setShowWholeSaleProductData(res)
                    originalWholesaleProductData.current = res
                })
            }).catch(err => {
                alert(err)
            })

            stats_api.countVisitor(PAGE_NAME);
        }, []
    )
    const loadMore = () => {
        setIsFetching(true)
        config.current.offset += config.current.limit;
        getWholeSaleProduct(config.current.offset, config.current.limit, config.current.keyword).then(res => {
            prepareProduct(config.current.offset, res.data).then(res => {
                let currentData = showWholesaleProductDataRef.current;
                currentData = currentData.concat(res)
                setShowWholeSaleProductData(currentData)
                originalWholesaleProductData.current = currentData
                setIsFetching(false)
            })
        }).catch(err => {
            alert(err)
        })
    }


    const prepareProduct = async (offset, productsData) => {
        return Promise.all(productsData.map(async (item, index) => {
            const result = await getProductImage(item.ProductID, 256);
            const image = result;
            const data = {
                col1: <span className='default-font'>{index + 1 + offset}</span>,
                col2: <div className='product-name'><img className='product-image' src={image}></img><span className='name default-font'>{item.Name}</span></div>,
                col3: item.Attr.map(attr => {
                    return <button className='attr button-style'>{attr.AttrValueName}</button>
                }),
                col4:
                    <BlinkingUnderLineNumber blink_type='<=' value={item.ready_stock} blink_value_threshold={item.low_stock} color={'black'} blink_note={'High demand'} />
                ,
                col5: <span className={'default-font'}>{parseFloat(item.CurrentSalePrice[0]['price']).toFixed(2)}</span>,
                col6: <span className={'default-font'}>{parseFloat(item.CurrentSalePrice[1]['price']).toFixed(2)}</span>,
                col7: <span className={'default-font'}>{parseFloat(item.CurrentSalePrice[2]['price']).toFixed(2)}</span>,
                col8: <span className={'default-font'}>{parseFloat(item.CurrentSalePrice[3]['price']).toFixed(2)}</span>,
                col9: <span className={'default-font'}>{parseFloat(item.CurrentSalePrice[4]['price']).toFixed(2)}</span>,
            }
            return data;
        }
        ))
    }

    const searchItem = () => {
        // Prevent search for 3 seconds if user is typing

        // Reset offset
        config.current.offset = 0;
        config.current.keyword = searchTerm;
        setShowWholeSaleProductData([])
        setIsFetching(true)
        getWholeSaleProduct(config.current.offset, config.current.limit, config.current.keyword).then(res => {
            prepareProduct(config.current.offset, res.data).then(res => {
                setShowWholeSaleProductData(res)
                originalWholesaleProductData.current = res
                setIsFetching(false)
            })
        }).catch(err => {
            alert(err)
        })
    }

    // Search after user stop typing for 1 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            searchItem();
        }, 1000);
        return () => clearTimeout(timer);
    }, [searchTerm]);




    return (
        <div className='wholesale'>
            <NavBar name="Wholesale" />
            <div className="content-container">

                <div className="content-header">

                    <div className="wholesale-title big-font">ตารางราคาขายส่ง โดยประมาณ</div>
                    <div className="search-bar">
                        <i className="fa-solid fa-magnifying-glass search-icon"></i>
                        <input className='search-input default-font' placeholder='ค้นหาสินค้า' onChange={(e) => setSearchTerm(e.target.value)}></input>
                    </div>
                </div>
                <div className="content-wrapper">

                    {
                        onLoad ? <Spinner customText="กำลังดึงข้อมูล อาจใช้เวลา 1-3 นาที" /> : <>
                            <FlexTable header={wholesaleTableHeaders} data={showWholesaleProductData} onBottomHit={loadMore} />
                        </>
                    }
                </div>
                <div className="fetching">
                    {isFetching && 'กำลังดึงข้อมูล...'}
                </div>
                <div className="content-footer">
                    {/* <span className='default-font'>Total visitor today {totalVisitor}</span> */}
                </div>
            </div>
        </div>
    )
}
