import axios from "axios";
import { apiUrl } from "../../config";

export const trigger = (function_name) => {
    const body = {
        function_name: function_name
    };
    return axios.post(apiUrl + "/internal/functions/status/triggers", body);
};

export const getStatus = (function_name) => {
    const params = {
        function_name: function_name
    };
    return axios.get(apiUrl + "/internal/functions/status", { params: params });
};
