import { ROLE_ADMIN } from "../../utils/permission";

const navBarListRaw = [
    {
        name: 'Main',
        path: '/',
    },
    {
        name: 'Blogs',
        path: '/blogs',
    },
    {
        name: 'Wholesale',
        path: '/wholesale',
    },
    // {
    //     name: 'Lowest Price',
    //     path: '/campaign/always-lowest-prices',
    // },
    {
        name: 'e-Receipt',
        path: '/receipt',
    },
    {
        name: 'Self Quotation',
        path: '/self-quotation',
    },
    {
        name: 'Contact',
        path: '/contact',
    },
    {
        name: 'Admin',
        path: '/admin',
        require_role: [ROLE_ADMIN]
    }
];


export const navBarList = navBarListRaw.map((item, index) => {
    return {
        ...item,
        key: index
    }
})