export const ReceiptHeader = [
    {
        Header:
            <div className="header-table extra-small-font">
                <span>รหัสสินค้า</span>
                <span>ITEM CODE</span>
            </div>,
        accessor: 'col1',
        width: '15%',
    },
    {
        Header:
            <div className="header-table extra-small-font">
                <span>รายการ</span>
                <span>DESCRIPTION</span>
            </div>,
        accessor: 'col2',
        width: '48%',
    },
    {
        Header: <div className="header-table extra-small-font">
            <span>จำนวณ</span>
            <span>QUANTITY</span>
        </div>,
        accessor: 'col3',
        width: '13%',
    },
    {
        Header: <div className="header-table extra-small-font">
            <span>ราคาต่อหน่วย</span>
            <span>UNIT PRICE</span>
        </div>,
        accessor: 'col4',
        width: '17%',
    },
    {
        Header: <div className="header-table extra-small-font">
            <span>จำนวณเงิน</span>
            <span>AMOUNT</span>
        </div>,
        accessor: 'col5',
        width: '8%',
    }
]
