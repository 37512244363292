export const findZipCode = (address) => {
    const zip = address.match(/\d{5}/)
    if (zip !== null) {
        return zip
    } else {
        return []
    }
}

export const findProvince = (zip_code, address) => {
    var thai = require('thai-data');
    var getDataForZipCode = thai.getDataForZipCode(zip_code);
    let target_province = null
    if (getDataForZipCode !== null) {
        let provinces = getDataForZipCode.provinceList
        for (let i = 0; i < provinces.length; i++) {
            if (address.includes(provinces[i].provinceName)) {
                target_province = provinces[i]
                break
            }
        }
    }
    let target_sub_district = null
    let target_province_name = ""
    let target_sub_district_name = ""
    if (target_province !== null) {
        target_province_name = target_province.provinceName
        let sub_districts = getDataForZipCode.subDistrictList
        for (let i = 0; i < sub_districts.length; i++) {
            if (address.includes(sub_districts[i].subDistrictName)) {
                const sub_district_province_id = sub_districts[i].provinceID
                if (sub_district_province_id === target_province.provinceID) {
                    target_sub_district = sub_districts[i]
                    break
                }
            }
        }
    }
    if (target_sub_district !== null) {
        target_sub_district_name = target_sub_district.subDistrictName
    }
    return [target_province_name, target_sub_district_name]
}