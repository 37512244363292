export const WholesaleTableHeaders = [
    {
        Header: <span className='default-font bold'>#</span>,
        accessor: 'col1',
        width: '5%'
    },
    {
        Header: <span className='default-font bold'>ชื่อสินค้า</span>,
        accessor: 'col2',
        width: '30%'
    },
    {
        Header: <span className='default-font bold'>ตัวเลือก</span>,
        accessor: 'col3',
        width: '15%'
    },
    {
        Header: <span className='default-font bold'>พร้อมจัดส่ง (ชิ้น)</span>,
        accessor: 'col4',
        width: '15%'
    },
    {
        Header: <span className='default-font bold'>1-9 ชิ้น</span>,
        accessor: 'col5',
        width: '10%'
    },
    {
        Header: <span className='default-font bold'>10-49 ชิ้น</span>,
        accessor: 'col6',
        width: '10%'
    },
    {
        Header: <span className='default-font bold'>50-99 ชิ้น</span>,
        accessor: 'col7',
        width: '10%'
    },
    {
        Header: <span className='default-font bold'>100-499 ชิ้น</span>,
        accessor: 'col8',
        width: '10%'

    },
    {
        Header: <span className='default-font bold'>> 500 ชิ้น</span>,
        accessor: 'col9',
        width: '10%'
    }
]