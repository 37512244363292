export const encrypt = (data) => {
    let base64 = require('base-64');
    let utf8 = require('utf8');

    let bytes = utf8.encode(data);
    let encrypted = base64.encode(bytes);
    return encrypted;
}

export const decode = (data) => {
    let base64 = require('base-64');
    let utf8 = require('utf8');

    let bytes = base64.decode(data);
    let decrypted = utf8.decode(bytes);
    return decrypted;
}