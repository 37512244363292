import './FlexTable.scss';

import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

export function FlexTable({ header, data, onBottomHit }) {
    const [isBottonHit, setIsBottonHit] = React.useState(false);
    const handleOnScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
            if (!isBottonHit) {
                setIsBottonHit(true);
                onBottomHit();
            }
        }
        else {
            setIsBottonHit(false);
        }
    };
    return (
        <div className='flex-table'>
            <div className='header'>
                {header.map((item, index) => {
                    return <div className='header-item' style={{ width: item.width }}>{item.Header}</div>
                })}
            </div>
            <div className='body' onScroll={handleOnScroll}>
                {data.map((item, index) => {
                    return (
                        <div className='row'>
                            <ReactTooltip />

                            {header.map((header, index) => {
                                return <div className='cell' style={{ width: header.width }}>{item[header.accessor]}</div>
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
