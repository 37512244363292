import React from 'react'
import NavBar from '../../components/navBar/NavBar'
import './Main.scss'

export default function Main() {
    return (
        <div className='main'>
            <NavBar name="Main" />
            <div className='main-content'>
                <h3 className='bold'>
                    ยินดีต้อนรับสู่เว็บไซต์ของเรา <br />กดแถบ Wholesale เพื่อดูรายการสินค้า หรือ Contact  เพื่อติดต่อเรา
                </h3>
            </div>
        </div>
    )
}
