import React, { useRef, useState } from 'react'
import './SelfQuotationCreate.scss'
import NavBar from '../../../../components/navBar/NavBar'
import CustomerForm from './sub-containers/CustomerForm/CustomerForm';
import ProductForm from './sub-containers/ProductForm/ProductForm';
import SelfQuotationCreateAction from './sub-containers/SelfQuotationAction/SelfQuotationAction';
import * as OrderAPI from '../../../../utils/API/Order'

export default function SelfQuotationCreate() {

    const selectedProductList = useRef([])
    const phone_number = window.location.pathname.split('/')[2];

    const [customerDetail, setCustomerDetail] = useState({})
    const [discountPrice, setDiscountPrice] = useState(0)
    const [shipping_price, setShipping_price] = useState(0)


    /**
     * confirmQuotation function
     * @param {boolean} quotation - A boolean parameter
     */
    const confirmQuotation = (quotation) => {
        // Dont included image
        const productList = selectedProductList.current.map(product => {
            return {
                product: product.product,
                quantity: product.quantity,
                price: product.price,
            }
        })
        const orderDetails = {
            customer: customerDetail,
            products: productList,
            paid_shiping_amount: shipping_price,
            discount_amount: discountPrice,
            quotation: quotation,
        }
        OrderAPI.postQuotation(orderDetails).then(res => {
            const data = res.data.data
            const order_id = data.order_id
            const create_time = data.create_time
            const serial_number = data.serial_number
            // Redirect to the /receipt/detail/serial_number.create_time
            window.location.href = `/receipt/detail/${serial_number}.${create_time}`

        })
    }

    return (
        <div
            className='pages-self-quotation-sub-page-self-quotation-create'
        >
            <NavBar name='Self Quotation' clickAble={false} />
            <div className="self-quotation-create-content">
                <div className="self-quotation-create-content-wrapper">
                    <div className="self-quotation-actions">
                        <SelfQuotationCreateAction confirmQuotation={confirmQuotation}/>
                    </div>
                    <div className='title'>
                        <h3 className='bold'>ใบเสนอราคา สำหรับผู้ซื้อ {phone_number}</h3>
                    </div>
                    <div className="body">
                        <CustomerForm
                            customerDetail={customerDetail}
                            setCustomerDetail={setCustomerDetail}
                            phone_number={phone_number}
                        />
                        <ProductForm
                            selectedProductList={selectedProductList}
                            discountPrice={discountPrice}
                            setDiscountPrice={setDiscountPrice}
                            shipping_price={shipping_price}
                            setShipping_price={setShipping_price}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
