export const debounce = (func, wait, immediate) => {
    /**
     * Debounce function to prevent multiple calls to a function within a given time frame.
     * @param {function} func - The function to be debounced.
     * @param {number} wait - The time frame in milliseconds.
     * @param {boolean} immediate - Whether to call the function immediately or not.
     * @returns {function} - The debounced function.
     * @example
     * const debouncedFunction = debounce(() => console.log('Hello world'), 1000);
     * debouncedFunction(); // Will log 'Hello world' after 1000ms.
     */
    let timeout;
    return (...args) => {
        const context = this;
        const later = () => {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait || 0);
        if (callNow) {
            func.apply(context, args);
        }
    };
}