import React, { useEffect } from 'react'
import './BlogsFloater.scss'
import * as ImagesAPI from '../../../utils/API/images-api'
import { redirect } from '../../../globalMethods'

export default function BlogsFloater({ childKey, detail }) {
    const [coverImage, setCoverImage] = React.useState(null)
    const callback = (reader) => {
        setCoverImage(reader.result)
    }
    useEffect(() => {
        const url = detail.coverImg
        ImagesAPI.loadImage(url,callback)
    }, [childKey])

    return (
        <div className='blogs_floater hover-animation-default' key={childKey}
            onClick={() => {
                redirect(`/blog/detail/${detail._id.$oid}`)
            }}
        >
            <img src={coverImage ? coverImage:
                './demoImg/blogPlaceholder.png'
            } alt="" />
            <div className="title">
                {detail.title}
            </div>
            <div className="create_time">
                {new Date(detail.create_time*1000).toLocaleDateString()}
            </div>
        </div>
    )
}
