export const InformationSection = [
    {
        title: 'Always lowest price Campaign คืออะไร!?',
        body: ['Always lowest price Campaign คือ แคมเปญที่จะช่วยผู้ซื้อให้สามารถซื้อสินค้าได้ในราคาถูกยิ่งขึ้น โดยเราจะทำการปรับราคาสินค้าภายในร้านเท่ากับราคาที่แจ้ง และ ผู้แจ้งจะได้โค้ดส่วนลดพิเศษ จากการแจ้งสินค้าราคาต่ำกว่าอีกด้วย เพราะฉนั้น ผู้ซื้อจะได้ซื้อสินค้าในราคาต่ำที่สุด']
    },
    {
        title: 'สิทธิพิเศษที่จะได้รับ',
        body: [
            '1. ได้ซื้อสินค้าราคาต่ำที่สุด',
            '2. ได้รับโค้ดส่วนลด 1% ไม่จำกัดจำนวนครั้ง อายุการใช้งาน 1 เดือน สั่งซื้อขั้นต่ำ 100 บาท/ครั้ง',
        ]
    },
    {
        title: 'เงื่อนไขการพิจารณาแจ้งสินค้าราคาถูกกว่า',
        body: [
            'ขณะนี้รองรับแค่จาก Shopee',
            '1. สินค้าต้องมาจากร้านค้าแนะนำ',
            '2. สินค้าที่แจ้งต้องมีราคาถูกกว่าทางร้าน',
            '3. สินค้าที่แจ้งต้องตรงกับสินค้าของทางร้าน',
        ]
    },
    {
        title: 'ขั้นตอนการรับสิทธิพิเศษ',
        body: [
            '1. กรอกแบบฟอร์มเพื่อแจ้งสินค้าราคาถูกกว่า',
            '2. ทำการทักไลน์ Official ของทางร้าน',
            '3. ทางร้านจะทำการตรวจสอบ (1-3 วัน)',
            '4. ทำการส่งโค้ดส่วนลดให้ทางไลน์ (ไลน์ ID ที่กรอกในแบบฟอร์ม)',
    ]
    },
]