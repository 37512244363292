import React from 'react'
import NavBar from '../../../components/navBar/NavBar'
import './AlwaysLowestPrices.scss'
import * as Datas from './Datas'
import * as GlobalMethods from '../../../globalMethods'

export default function AlwaysLowestPrices() {
    return (
        <div className='always-lowest-prices'>
            <NavBar name="Lowest Price" />
            <div className="content">
                <div className="title">
                    <h2>Always lowest price (Coming soon!)</h2>
                    <h3 className='bold'>แจ้งสินค้าราคาถูกกว่า (พบกันเร็วๆนี้)</h3>
                </div>
                <div className="body">
                    <div className="information-section">
                        {Datas.InformationSection.map((item, index) => {
                            return (
                                <div className="block1">
                                    <div className="block1-title bold">
                                        {item.title}
                                    </div>
                                    <div className="block1-body">
                                        {item.body.map((item, index) => {
                                            return (<>
                                                {item}<br />
                                            </>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="action">
                        <button className="btn"
                        onClick={()=>{GlobalMethods.redirect('/campaign/always-lowest-prices/form')}}
                        >แจ้งสินค้าราคาถูกกว่า</button>
                    </div>
                </div>
            </div>

        </div>
    )
}
