export const ROLE_ADMIN = 'admin';
export const ROLE_USER = 'user';

export function is_satisfy_role(userInfo, require_role) {
    if (require_role) {
        if (userInfo) {
            if (userInfo.roles) {
                for (var i = 0; i < require_role.length; i++) {
                    if (userInfo.roles.includes(require_role[i])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    else {
        return true;
    }
}