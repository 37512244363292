import axios from 'axios'
import { apiUrl } from '../config'

export const getProducts = (
    keyword = ''
) => {
    const params = {
        keyword: keyword
    }
    return axios.get(apiUrl + '/products/detail', { params: params });
}

export async function getProductImage(productID, size) {
    let result = null;
    try {
        result = await axios.get(apiUrl + '/images/product',
            {
                params: {
                    product_id: productID,
                    size: size
                },
                headers: {
                    'content-type': 'image/jpeg'
                },
                responseType: 'blob'
            })
    }
    catch (err) {
        console.log("Error in getProductImage: ", productID)
    }
    if (result) {

        return new Promise(async (resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(result.data);
            reader.onloadend = function (e) {
                return resolve(reader.result);
            }
            reader.onerror = reject;
        })
    }
    else {
        return null;
    }
}

export async function getWholeSaleProduct(offset,limit,keyword=''){
    const params = {
        offset: offset,
        limit: limit,
        keyword: keyword
    }
    const result = await axios.get(apiUrl + '/wholeSale/product', {params: params})
    return result;
}

export async function getTotalVisitor(){
    const result = await axios.get(apiUrl + '/wholeSale/totalVisitor')
    return result;
}

export async function login(email, password) {
    const result = await axios.post(apiUrl + '/user/login', {
        email: email,
        password: password
    })
    return result;
}

export async function checkToken(token) {
    const result = await axios.post(apiUrl + '/user/checkToken', {
        token: token
    })
    return result;
}

export const build_headers = (token) => {
    return {
        'Authorization': token
    }
}


export async function getUserInfo(token) {
    const result = await axios.get(apiUrl + '/user/info', { headers: build_headers(token) })
    return result;
}

export async function getOrderList() {
    const result = await axios.get(apiUrl + '/internal/orderList',
        {
            params: {
                'config': 'active'
            }
        });

    return result;
}
