import React, { useEffect } from 'react'
import NavBar from '../../components/navBar/NavBar'
import RolesChecker from '../../components/RolesChecker/RolesChecker'
import { ROLE_ADMIN } from '../../utils/permission'
import './Blogs.scss'
import { redirect } from '../../globalMethods'
import * as BlogsApi from '../../utils/API/blogs-api'
import BlogsFloater from './sub_components/BlogsFloater'

export default function Blogs() {
    const [userInfo, setUserInfo] = React.useState(null)
    const [blogsList, setBlogsList] = React.useState([])

    useEffect(() => {
        BlogsApi.getBlogs().then(res => {
            setBlogsList(res.data.blogs)
        }).catch(err => {
            setBlogsList(null)
        })
    }, [])

    return (
        <div className='blogs'>
            <NavBar name='Blogs' clickAble={false} setUserInfo={setUserInfo} />
            <div className='blogsContent'>
                <div className="header">
                    <h3 className='bold'>
                        Blogs
                    </h3>
                    <div className="action">
                        <RolesChecker requireRoles={[ROLE_ADMIN]} userInfo={userInfo}>
                            <button className="btn btn-primary small-font" onClick={() => {
                                redirect('/blog/new')
                            }}
                            >New Blog</button>
                        </RolesChecker>
                    </div>
                </div>
                <div className="body">
                    <div className="body_blogs">
                        {
                            blogsList.length>0 ? blogsList.map((blog, index) => {
                                return (
                                    <div className="blog_wrapper" ><BlogsFloater childKey={index} detail={blog} /></div>
                                )
                            }) :
                                <div className='title'>
                                    Currently, there are no blogs.
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
