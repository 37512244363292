import React, { useCallback, useEffect, useState } from 'react'
import './CustomerForm.scss'
import '../../../../../../styles/Text.scss'
import * as AddressUtils from '../../../../../../utils/Address'
import * as CustomerAPI from '../../../../../../utils/API/Customer'
export default function CustomerForm(
    {
        customerDetail,
        setCustomerDetail,
        phone_number
    }
) {
    const [customerList, setCustomerList] = useState([])

    const getCustomers = () => {
        CustomerAPI.getCustomersByPhoneNumber(phone_number).then(res => {
            const customers = res.data.response
            if (customers !== undefined) {
                setCustomerList(customers)
            }
        })

    }

    useEffect(() => {
        setCustomerDetail({ ...customerDetail, Phone: phone_number })
        getCustomers()
    }, [])

    const updateCustomerDetail = (value, fieldname) => {
        if (fieldname === 'name') {
            setCustomerDetail({ ...customerDetail, Name: value })
        } else if (fieldname === 'address') {
            const zip_codes = AddressUtils.findZipCode(value)
            let target_zip_code = ''
            let target_province = ''
            let target_sub_distric = ''
            zip_codes.forEach(zip_code => {
                let [province, sub_distric] = AddressUtils.findProvince(zip_code, value)
                if (province !== '') {
                    target_zip_code = zip_code
                    target_province = province
                    target_sub_distric = sub_distric
                }
            })

            setCustomerDetail({
                ...customerDetail,
                Address: value,
                Zip: target_zip_code,
                State: target_province,
                City: target_sub_distric
            })
        } else if (fieldname === 'phone') {
            setCustomerDetail({ ...customerDetail, Phone: value })
        } else if (fieldname === 'zip') {
            setCustomerDetail({ ...customerDetail, Zip: value })
        } else if (fieldname === 'state') {
            setCustomerDetail({ ...customerDetail, State: value })
        } else if (fieldname === 'city') {
            setCustomerDetail({ ...customerDetail, City: value })
        }
    }

    const selectCustomer = useCallback((e, customerValue) => {
        var foundCustomer = false;
        customerList.forEach(customer => {
            if (customerValue === customer.Name) {
                foundCustomer = true
                setCustomerDetail(customer)
            }
        })
        if (!foundCustomer) {
            if (customerValue === '') {
                // Change customer ID to null
                const newCustomerDetail = { ...customerDetail }
                newCustomerDetail.CustomerID = null
                setCustomerDetail(newCustomerDetail)
            }
            // Set customer name to customerValue
            const newCustomerDetail = { ...customerDetail }
            newCustomerDetail.Name = customerValue
            setCustomerDetail(newCustomerDetail)
        }
    }, [customerList, customerDetail])


    return (
        <div className='sub-page-self-quotation-create-sub-container-customer-form'>

            <div className="content-p1">
                <div className='customer-detail'>
                    <h3 className='text-bold'>ขั้นตอนที่ 1 : ที่อยู่สำหรับจัดส่งสินค้า</h3>
                    <div className="input-text-box">
                        <label>ชื่อผู้รับ: </label>
                        <input className='input-text input-text-regular'
                            type={'search'}
                            id='name-search'
                            placeholder={'ชื่อผู้รับสินค้า'}
                            list='customerList'
                            onSelect={e => selectCustomer(e, e.target.value)}
                        />
                        <datalist id='customerList'>
                            {customerList.map((item, index) =>
                                <option key={index} value={item.Name} >{item.Address}</option>
                            )}
                        </datalist>
                    </div>
                    <div className="input-text-box">
                        <label>ที่อยู่: </label>
                        <textarea className='input-text input-text-regular' value={customerDetail.Address} onChange={e => updateCustomerDetail(e.target.value, 'address')} placeholder={'ที่อยู่เต็ม รวมถึง อำเภอ จังหวัด และ รหัสไปรษณีย์'} rows={1} />
                    </div>
                    <div className="input-text-box">
                        <label>รหัสไปรษณีย์: </label>
                        <input className='input-text input-text-regular' value={customerDetail.Zip} onChange={e => updateCustomerDetail(e.target.value, 'zip')} type={'search'} id='zip' placeholder={'อัตโนมัติ'} disabled/>
                        <label>จังหวัด: </label>
                        <input className='input-text input-text-regular' value={customerDetail.State} onChange={e => updateCustomerDetail(e.target.value, 'state')} type={'search'} id='state-province' placeholder={'อัตโนมัติ'} list='mylist' disabled/>
                        <label>อำเภอ: </label>
                        <input className='input-text input-text-regular' value={customerDetail.City} onChange={e => updateCustomerDetail(e.target.value, 'city')} type={'search'} id='state-province' placeholder={'อัตโนมัติ'} list='mylist' disabled/>
                    </div>
                    <div className="input-text-box">
                        <label>เบอร์โทร: </label>
                        <input className='input-text phonenumber input-text-regular' value={customerDetail.Phone} type={'search'} id='phonenumber' onChange={e => updateCustomerDetail(e.target.value, 'phone')} placeholder={''} list='mylist' disabled/>
                    </div>
                </div>

            </div>
        </div>
    )
}
