import React, { useEffect } from 'react';

const DoAfterStopSecond = ({ callback, dependencies, time }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, time);
    return () => clearTimeout(timer);
  }, dependencies);

  return null;
};

export default DoAfterStopSecond;