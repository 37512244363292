export const codeMapperNumber = (code) => {
    // Map the product code to a number
    // Example AAAAA -> 01 01 01 01 01
    // Example AABAA -> 01 02 01 01 01
    // Example AABZB -> 01 02 01 26 02

    // Split the code into an array
    var codeArray = code.split('')
    // Map the array to a number
    var codeNumber = codeArray.map((item) => {
        return item.charCodeAt(0) - 64
    })
    // Change the array to a string and add 0 in front of single digit numbers
    codeNumber = codeNumber.map((item) => {
        if (item < 10) {
            return '0' + item
        }
        else {
            return item
        }
    })
    // Join the array into a string
    codeNumber = codeNumber.join(' ')
    return codeNumber
}