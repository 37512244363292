import axios from "axios";
import { apiUrl } from "../../config";

export function getReceiptDetail(orderSN,password) {
    const result = axios.get(apiUrl + '/receipt', { params: { orderSN: orderSN, password: password } })
    return result;
}

export async function updateReceiptDetail(orderSN, password, data) {
    const result = await axios.put(apiUrl + '/receipt', data, { params: { orderSN: orderSN, password: password } })
    return result;
}
export async function getOrderPaymentDetail(orderID) {
    const result = await axios.get(apiUrl + '/payment/order/detail/', {
        params: {
            orderID: orderID
        }
    })
    return result;
}
