export const SalesListHeader = [
    {
        Header: '',
        accessor: 'col0',
    },
    {
        Header: 'ID',
        accessor: 'col1'
    },
    {
        Header: 'Code',
        accessor: 'col2'
    },
    {
        Header: 'Product',
        accessor: 'col3'
    },
    {
        Header: 'Attribute',
        accessor: 'col4'
    },
    {
        Header: 'Qty',
        accessor: 'col5'
    },
    {
        Header: 'Price/Qty',
        accessor: 'col6'
    },
    {
        Header: 'Total',
        accessor: 'col7'
    }
]