import React from 'react'
import './LoginPopup.scss'
import * as api from '../../../utils/atrobot-api'


export default function LoginPopup({ onCancel }) {
    const email_ref = React.useRef(null)
    const password_ref = React.useRef(null)


    const login = async () => {
        const email = email_ref.current.value
        const password = password_ref.current.value
        try {
            const result = await api.login(email, password)

            // Set the token to local storage
            localStorage.setItem('token', result.data.token)
            alert('Login success')
            onCancel()

        } catch (error) {
            alert('Login failed')
        }
    }

    return (
        <div className='login_popup'>
            <div className='login_popup_content'>
                <div className='login_popup_input'>
                    <div className='login_popup_input_title'>
                        Username
                    </div>
                    <input type="text" ref={email_ref} />
                </div>
                <div className='login_popup_input'>
                    <div className='login_popup_input_title'>
                        Password
                    </div>
                    <input type="password" ref={password_ref} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            login()
                        }
                    }} />
                </div>
            </div>
            <div className='login_popup_action'>
                <div className='login_popup_action_button hover-animation-default' onClick={login}>
                    Login
                </div>
                <div className='login_popup_action_button hover-animation-default' onClick={onCancel}>
                    Cancel
                </div>
            </div>
        </div>
    )
}
