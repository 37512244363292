import React from 'react'
import NavBar from '../../components/navBar/NavBar'

export default function Admin() {
    return (
        <div className='admin'>
            <NavBar />
            <div className="content">
                <h1>Admin</h1>
            </div>
        </div>
    )
}
