import React, { useEffect } from 'react'
import NavBar from '../../../../components/navBar/NavBar'
import './NewBlog.scss'
import ContentEditable from 'react-contenteditable'
import useState from 'react-usestateref'
import * as permission from '../../../../utils/permission'
import * as BlogsApi from '../../../../utils/API/blogs-api'
import * as ImagesApi from '../../../../utils/API/images-api'
import { imageAPI } from '../../../../config'


export default function NewBlog() {
    const [userInfo, setUserInfo] = React.useState(null)
    const saveBody = React.useRef(null)
    const [forceRender, setForceRender] = React.useState(false)
    const [bodyReference, setBodyReference, bodyReferenceRef] = useState('')
    const [bodyRaw, setBodyRaw, bodyRawRef] = useState('')
    const [title, setTitle] = useState('')
    const [permissionGranted, setPermissionGranted] = useState(false)
    const token = localStorage.getItem('token')
    const imageCoverInputRef = React.useRef(null)
    const imagesInputRef = React.useRef(null)
    const [coverImage, setCoverImage] = useState(null)
    const [images, setImages, imagesRef] = useState([])


    // Ask confirmation before leaving the page
    React.useEffect(() => {
        const granted = permission.is_satisfy_role(userInfo, [permission.ROLE_ADMIN])
        setPermissionGranted(granted)

        window.onbeforeunload = function () {
            return true;
        }
    }, [userInfo])

    return (
        <div className='new_blog'>
            <NavBar name='Blogs' setUserInfo={setUserInfo} askBeforeLeave={true} />
            <div className='new_blog_content'>
                {permissionGranted ?
                    <>
                        <div className="header">
                            <div className='title'>
                                New Blog
                            </div>
                            <div className="action">
                                <button className="btn btn-primary small-font"
                                    onClick={() => {
                                        const content = saveBody.current.innerHTML;
                                        BlogsApi.createBlog(token, title, content, coverImage).then(res => {
                                            alert('Blog created')
                                            // Turn off the confirmation before leaving the page
                                            window.onbeforeunload = null
                                            window.location.href = '/blogs'
                                        }).catch(err => {
                                            // Show the error message from server
                                            alert('Error ' + err.response.status + ': ' + err.response.data.message)
                                        })
                                    }}
                                >Save</button>
                                <button className="btn btn-primary small-font"
                                    onClick={() => {
                                        // Exit the page
                                        window.location.href = '/blogs'
                                    }}
                                >Cancel</button>

                            </div>
                        </div>
                        <div className="body">
                            <div className="blog_action">
                                <button className="btn btn-primary small-font"
                                    onClick={() => {
                                        // Insert bold text
                                        const newBodyRawRef = bodyRawRef.current + '&lt;div class="bold"&gt;Bold text&lt;/div&gt;'
                                        setBodyRaw(newBodyRawRef)
                                        const newBodyReferenceRef = bodyReferenceRef.current + '<div class="bold">Bold text</div>'
                                        setBodyReference(newBodyReferenceRef)

                                    }}
                                >Bold Text</button>
                                <button className="btn btn-primary small-font"
                                    onClick={() => {
                                        // Insert Image
                                        const newBodyRawRef = bodyRawRef.current + '&lt;img src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png" /&gt;'
                                        setBodyRaw(newBodyRawRef)
                                        const newBodyReferenceRef = bodyReferenceRef.current + '<img src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png" />'
                                        setBodyReference(newBodyReferenceRef)

                                    }}
                                >Image</button>
                                <button className="btn btn-primary small-font"
                                    onClick={() => {
                                        // New line
                                        const newBodyRawRef = bodyRawRef.current + '&lt;br /&gt;'
                                        setBodyRaw(newBodyRawRef)
                                        const newBodyReferenceRef = bodyReferenceRef.current + '<br />'
                                        setBodyReference(newBodyReferenceRef)

                                    }}
                                >New line</button>
                            </div>
                            <div className="body_left">
                                <div className="blog_title">
                                    <input type="text" className='no-input-style title_input' placeholder='Title' value={title} onChange={(e) => {
                                        setTitle(e.target.value)
                                    }} />
                                </div>
                                <div className="blog_body">
                                    <ContentEditable html={
                                        bodyRawRef.current
                                    } onChange={(e) => {
                                        const current_body = e.currentTarget.innerText;
                                        setBodyReference(current_body)
                                        const body_raw = e.currentTarget.innerHTML;
                                        setBodyRaw(body_raw)
                                    }}
                                        placeholder='Type something here'
                                        className='blog_html'
                                    />
                                </div>
                            </div>
                            <div className="body_right">
                                <div className="blog_preview">
                                    <div className="blog_preview_title">
                                        {title}
                                    </div>
                                    <div className="blog_preview_body" ref={saveBody}>
                                        <ContentEditable html={
                                            bodyReference
                                        } disabled={true}
                                            className='blog_html'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            {/* Cover image */}
                            <div className="cover_image">
                                <div className="cover_image_title bold">
                                    Cover Image
                                </div>
                                <div className="cover_image_body">
                                    <img src={coverImage ? coverImage['image'] : ('/demoImg/blogPlaceHolder.png')}    
                                    alt="" 
                                    onClick={() => {
                                        imageCoverInputRef.current.click()
                                    }}
                                    />
                                    {/* Hidden the input */}
                                    <input type="file" className='no-input-style hide' ref={imageCoverInputRef} onChange={(e) => {
                                        const file = e.target.files[0]
                                        if (file) {
                                            const reader = new FileReader()
                                            reader.onload = (e) => {
                                                ImagesApi.postImage(file).then(res => {
                                                    setCoverImage({'image':e.target.result, 'source':res.url})
                                                }).catch(err => {
                                                    alert('Error ' + err.response.status + ': ' + err.response.data.message)
                                                }
                                                )
                                            }
                                            reader.readAsDataURL(file)
                                        }
                                    }} />


                                </div>
                            </div>
                            <div className="images">
                                <div className="cover_image_title bold">
                                    Images
                                </div>
                                <div className="cover_image_body">
                                    <img src={'/demoImg/blogPlaceHolder.png'}    
                                    alt="" 
                                    onClick={() => {
                                        imagesInputRef.current.click()
                                    }}
                                    />
                                    {images.map((image, index) => {
                                        return (
                                            <img src={image['image']} alt="" key={index} 
                                                onClick={() => {
                                                    const newBodyRawRef = bodyRawRef.current + '&lt;img src="'+imageAPI+'/images?url=' + image['source'] + '" /&gt;'
                                                    setBodyRaw(newBodyRawRef)
                                                    const newBodyReferenceRef = bodyReferenceRef.current + '<img src="'+imageAPI+'/images?url=' + image['source'] + '" />'
                                                    setBodyReference(newBodyReferenceRef)
                                                }}
                                            />
                                        )
                                    }
                                    )}
                                    
                                    {/* Hidden the input */}
                                    <input type="file" className='no-input-style hide'  accept='image/*'
                                    multiple ref={imagesInputRef} onChange={(e) => {
                                        const files = e.target.files
                                        if (files) {
                                            for (let i = 0; i < files.length; i++) {
                                                var reader = new FileReader()
                                                let file = files[i]
                                                reader.readAsDataURL(file)
                                                reader.onloadend = function (e) {
                                                    const result = e.target.result;
                                                    ImagesApi.postImage(result).then(res => {
                                                        setImages([...imagesRef.current, {'image':result, 'source':res.url}])
                                                    }).catch(err => {
                                                        alert('Error ' + err.response.status + ': ' + err.response.data.message)
                                                    })
                                                }
                                            }
                                        }
                                    }} />


                                </div>
                            </div>
                        </div>
                    </> :
                    <h3 className='bold'>You don't have permission to access this page</h3>
                }

            </div>
        </div>
    )
}
