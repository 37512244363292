import React, { useEffect, useMemo } from 'react'
import NavBar from '../../../../components/navBar/NavBar'
import './ReceiptCheck.scss'
import * as SecurityUtils from '../../../../utils/security-utils';
import * as ReceiptAPI from '../../../../utils/API/receipt-api';
import * as ReceiptUtils from '../ReceiptDetailUtils';
import { ReceiptDetailToPrint } from '../../components/ToPrint';
import { ReceiptHeader } from '../receipt_detail/TableColumns';

export default function ReceiptCheck() {
    const id = window.location.pathname.split('/')[3];
    const decoded_id = SecurityUtils.decode(id);
    const [orderSN, orderID, orderCreateTime] = decoded_id.split('|');
    const [receiptData, setReceiptData] = React.useState([]);
    const [orderDetail, setOrderDetail] = React.useState(null);
    const [receiptDetail, setReceiptDetail] = React.useState(null);
    const receiptHeader = useMemo(() => ReceiptHeader, []);


    console.log(orderSN, orderID, orderCreateTime);
    useEffect(() => {
        ReceiptAPI.getReceiptDetail(orderSN, orderCreateTime).then((res) => {
            const data = res.data;
            if (data.ReceiptAddress === null) {
                data.ReceiptAddress = {}
            }
            setReceiptDetail(data);
            ReceiptAPI.getOrderPaymentDetail(orderID).then((res) => {
                const data = res.data.response;
                ReceiptUtils.prepareReceiptData(data, setReceiptData);
                setOrderDetail(data);
            })
        })
    }, [])


    return (
        <div className='receiptcheck'>
            <NavBar name='Receipt' />
            <div className="content">
                <div className="receipt-copy">
                    {receiptDetail &&
                        <ReceiptDetailToPrint tableHeader={receiptHeader} receiptDetail={receiptDetail} orderDetail={orderDetail} tableData={receiptData} CreateTime={
                            receiptDetail.OrderCreateTime
                        } orderSN={orderSN} />
                    }
                </div>
            </div>
        </div>
    )
}
