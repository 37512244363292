import React from 'react'
import Dropdown from 'react-dropdown';
import NavBar from '../../../../../components/navBar/NavBar'
import './Form.scss'
import 'react-dropdown/style.css';


export function Form() {

    const dataRef = React.useRef({})
    const SubmitData = () => {
        // Asking for confirmation
        if (window.confirm('ยืนยันการส่งข้อมูล?')) {
            // If confirmed, submit data
            console.log('submit')
            console.log(dataRef)
        }
    }

    return (
        <div className='always-lowest-prices-form'>
            <NavBar name="Lowest Price" />
            <div className="content">
                <div className="title">
                    <h2>แจ้งสินค้าราคาถูกกว่า Shopee</h2>
                </div>
                <div className="body">
                    <div className="item">
                        <div className="label bold">ลิงค์สินค้าที่ถูกกว่า</div>
                        <input type="text" className="input_text"
                            onChange={(e) => {
                                dataRef.current['target_link'] = e.target.value
                            }} />
                        <div className="label bold">ตัวเลือกสินค้า</div>
                        <Dropdown options={[]} />
                    </div>
                    <div className="item">
                        <div className="label bold">ลิงค์สินค้าของทางร้าน</div>
                        <input type="text" className="input_text"
                            onChange={(e) => {
                                dataRef.current['our_link'] = e.target.value
                            }} />
                        <div className="label bold">ตัวเลือกสินค้า</div>
                        <Dropdown options={[]} />
                    </div>
                    <div className="item">
                        <div className="label bold">ไลน์ ID สำหรับส่วนลดพิเศษ</div>
                        <input type="text" className="input_text"
                            onChange={(e) => {
                                dataRef.current['line_id'] = e.target.value
                            }} />
                        <button className="btn" onClick={
                            () => {SubmitData()}
                        }>ส่งข้อมูล</button>
                    </div>
                </div>

            </div>

        </div>
    )
}
