import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './navBar.scss'
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import LoginPopup from './sub_components/LoginPopup';
import * as api from '../../utils/atrobot-api'
import * as permission from '../../utils/permission'
import { navBarList } from './NavBarList';

// Make user info available to all components
export let userInfo_share = null;

export default function NavBar(props) {

    const [showLogInPopup, setShowLogInPopup] = React.useState(false)
    const [userInfo, setUserInfo] = React.useState(null)
    const token = localStorage.getItem('token')

    // Ask before leaving the page
    useEffect(() => {
        if (props.askBeforeLeave) {
            console.log('ask before leave')
            window.onbeforeunload = function () {
                return true;
            }
        }
    }, [])



    useEffect(() => {
        if (token) {
            api.checkToken(token).then((result) => {
            }).catch((err) => {
                // Remove the token from local storage
                localStorage.removeItem('token')
                // Redirect to main page
                window.location.href = '/'
            })
            api.getUserInfo(token).then((result) => {

                setUserInfo(result.data.user_info)
                if (props.setUserInfo) {
                    props.setUserInfo(result.data.user_info)
                }



            })
        }
    }, [token])


    const Logout = () => {
        localStorage.removeItem('token')
        window.location.href = '/'
    }


    return (
        <div className="navBar-wrapper">

            <div className='NavBar'>
                <div className="Left">
                    <div className="logo">
                        <img src="/logo.jpg" alt="" />
                    </div>

                    <ul className='menuList'>
                        {navBarList.map((item) => {
                            const require_role = item.require_role
                            let satisfy_role = permission.is_satisfy_role(userInfo, require_role)
                            if (satisfy_role) {
                                return <li key={item.key} className={'menu ' + ((props.name === item.name) && 'active')}>
                                    {
                                        (props.name === item.name && props.clickAble === false) ?
                                            <div className='MenuLink'><span className='default-font'>{item.name}</span></div> :
                                            <div className='MenuLink'
                                                onClick={() => {
                                                    window.location.href = item.path
                                                }}
                                            >
                                                <span className='default-font'

                                                >{item.name}</span>
                                            </div>
                                    }
                                </li>
                            }
                            else {
                                return null
                            }
                        }
                        )}
                    </ul>
                </div>
                {/* Login and registeration */}
                <div className="right">
                    <div className="header default-font">
                        {
                            userInfo ? <>
                                <div className='greeting'>Hi,</div>
                                <div>{userInfo.name}</div>

                            </> :
                                <>
                                    Welcome
                                </>
                        }
                    </div>
                    <div className="action">
                        {token ?
                            <>
                                <div className="setting small-font">
                                    Settings
                                </div>
                                <div className="logout small-font" onClick={Logout}>
                                    Logout
                                </div>
                            </> :
                            <>
                                <div className="login small-font" onClick={() => setShowLogInPopup(!showLogInPopup)}>
                                    Login
                                </div>
                                {false &&
                                    <div className="register small-font">
                                        Register
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>

            </div>
            <div className={'action-popup ' + (showLogInPopup ? 'unhide' : 'hide')}>
                <LoginPopup onCancel={() => setShowLogInPopup(false)} />
            </div>
        </div>
    )
}


