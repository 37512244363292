import React, { useEffect } from 'react'
import * as sku_utils from '../../../utils/sku_utils';
import * as AtrobotAPI from '../../../utils/atrobot-api'
import * as NumberTraysApi from '../../../utils/API/NumberTrays'
import './StoreListTable.scss'

export default function StoreListTable({ data }) {

    const [processed_data, setProcessed_data] = React.useState([]);

    useEffect(() => {
        console.log("Data")
        console.log(data)


        processData(data).then(res => {
            console.log("Processed data")
            console.log(res)
            console.log("End processed data")
            setProcessed_data(res)
        })
    }, [data])

    return (
        <div
            className='store-list-table-sub-component-store-list-page'
        >
            <div className="action-bar">
                <div className="action-type1">
                    <i
                        className="fas fa-sync-alt clear-icon"
                        onClick={() => {
                            // Ask for confirmation
                            if (window.confirm("Are you sure you want to clear all trays?")) {

                                NumberTraysApi.deleteTrays().then(res => {
                                    
                                    window.location.reload();
                                }).catch(err => {
                                    alert(err)
                                })
                            }
                        }}
                        ></i>
                    <div className="title">
                        <span>Clear trays</span>
                    </div>
                </div>
            </div>
            {/* Simple header with date */}
            <div className="store-list-header">
                <div className="header-wrapper bold size-big">
                    Store List - {new Date().toLocaleDateString()}
                </div>
            </div>
            {/* store list content */}
            <div className="store-list-content">
                <div className="store-list-content-wrapper">
                    <div className="store-list-item" key={"A"}>
                        <div className="store-list-item-wrapper">
                            <div className="index">
                                #
                            </div>
                            <div className="product-image">
                                Image
                            </div>
                            <div className="code">
                                Code
                            </div>
                            <div className="name">
                                Name
                            </div>
                            <div className="attr">
                                Attr
                            </div>
                            <div className="qty">
                                Qty
                            </div>
                        </div>
                    </div>

                    {Object.keys(processed_data).map((key, index) => {
                        const item = processed_data[key];
                        console.log(item)
                        return (
                            <div className="store-list-item" key={item.product_code}
                                onClick={() => {
                                    if (item.warning_note !== '') {
                                        // open the product detail page
                                        window.open(`/productDetail/${item.productID}`, '_blank');
                                    }
                                }}
                            >
                                <div className="store-list-item-wrapper">
                                    <div className="index text-bold">
                                        {item.item_num}
                                    </div>
                                    <div className="product-image">
                                        <img src={item.image} alt="product" />
                                    </div>
                                    <div className="code">
                                        <div className="text-bold">
                                            {
                                                item.product_code
                                            }
                                        </div>
                                        {
                                            sku_utils.codeMapperNumber(item.product_code)
                                        }
                                    </div>
                                    <div className="name">
                                        {item.name}
                                    </div>
                                    <div className="attr">
                                        {item.attr.map((attrItem, attrIndex) => {
                                            return (
                                                <div className="attr-item" key={attrIndex}>
                                                    {attrItem.name}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="qty text-bold">
                                        {item.qty}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>

        </div>
    )
}

async function processData(data) {
    // Initialize the processed data as an array
    let processed = [];

    // Loop through every dict key
    Object.keys(data).forEach((key) => {
        const item = data[key];
        const product_variation_detail = item.product_variation_detail;
        const product_code = product_variation_detail.code;
        const qty = item.qty;
        const item_num = item.tray_number;
        const name = product_variation_detail.product_name;
        const product_id = product_variation_detail.product_id;
        const attr = product_variation_detail.attributes;
        const qty_color = 'black';
        const warning_note = '';

        // Push each item as a separate entry in the processed array
        processed.push({
            qty: qty,
            name: name,
            productID: product_id,
            attr: attr,
            qty_color: qty_color,
            warning_note: warning_note,
            item_num: item_num,
            product_code: product_code
        });
    });

    // Get the image of the product preserving the product code
    processed = await Promise.all(processed.map(async (item) => {
        const result = await AtrobotAPI.getProductImage(item.productID, 256);
        item.image = result;
        return item;
    }));

    // Sort the processed data by the product code
    processed.sort((a, b) => {
        return a.product_code.localeCompare(b.product_code);
    });
    return processed;
}