import React, { useCallback, useState } from 'react'
import './LazyLoadSearchProductInput.scss'
import DoAfterStopSecond from '../../components/DoAfterSecond/DoAfterSecond';
import { getProductImage, getProducts } from '../../utils/atrobot-api';
import { useMemo } from 'react';
import '../../styles/Input.scss'

export default function LazyLoadSearchProductInput(
    {
        onSelect_product = () => { },
    }
) {
    const [productList, setProductList] = useState([])
    const [search_term, setSearch_term] = useState('')
    const productListOptions = useMemo(() => {
        return productList.map(item => {
            const var1 = item.Variation[0]?.name || '';
            const var2 = item.Variation[1]?.name || '';
            return <option value={`${item.ProductName} ${var1} ${var2}`} />
        })
    }, [productList])

    const selectProduct = useCallback(
        async (e, productValue) => {
            const selectedProduct = productList.find((product) => {
                const var1 = product.Variation[0]?.name || '';
                const var2 = product.Variation[1]?.name || '';
                return productValue === `${product.ProductName} ${var1} ${var2}`;
            });

            if (selectedProduct) {
                const img = await getProductImage(selectedProduct.ProductID, 512);
                setSearch_term('');
                onSelect_product(img, selectedProduct);
            }
        },
        [productList],
    );

    const searchProduct = () => {
        if (search_term.length > 0) {
            getProducts(search_term).then((res) => {
                const products = res.data.data;
                if (products !== undefined) {
                    setProductList(products);
                }
            });
        }
    }

    return (
        <div
            className="containers-lazy-load-search-product-input"
        >
            <DoAfterStopSecond callback={searchProduct} dependencies={[search_term]} time={1000} />
            <input className='input-product-name input-text-regular'
                placeholder={'ชื่อสินค้า'}
                onChange={e => setSearch_term(e.target.value)}
                list='lazy-load-serach-product-list'
                onSelect={e => selectProduct(e, e.target.value)}
                value={search_term} />
            <datalist id='lazy-load-serach-product-list'>
                {productListOptions}
            </datalist>
        </div>
    )
}
