import React from 'react'
import './SelfQuotation.scss'
import NavBar from '../../components/navBar/NavBar'
import { redirect } from '../../globalMethods';

export default function SelfQuotation() {

    const inputRef = React.useRef(null);

    const phoneNumberSubmitHandler = () => {
        const phone_number = inputRef.current.value;
        redirect('/self-quotation/' + phone_number);
    }

    return (
        <div
            className='pages-self-quotation'
        >
            <NavBar name='Self Quotation' clickAble={false} />
            <div className="self-quotation-content">
                <div className='title'>
                    <h3 className='bold'>ระบบจัดการใบเสนอราคา สำหรับผู้ซื้อ</h3>
                </div>
                <div className="body">
                    <div className="group1">
                        <div className="title bold">
                            โปรดกรอกเบอร์โทรศัพท์ที่ใช้ในการจัดส่งสินค้า
                        </div>
                        <div className="content">
                            <div className="item">
                                <div className="item__title">
                                    เบอร์โทรศัพท์
                                </div>
                                <div className="item__input">
                                    <input type="text" className="input_text"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                phoneNumberSubmitHandler();
                                            }
                                        }}
                                        ref={inputRef}
                                    />
                                    <i class="fa-sharp fa-solid fa-angle-right submit-btn btn-animation"
                                        onClick={() => {
                                            phoneNumberSubmitHandler();
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group1">
                        <div className="bold">
                            มีข้อสงสัย โปรดติดต่อเจ้าหน้าที่
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
