import React, { useEffect, useState } from 'react'
import StoreListTable from './sub-component/StoreListTable'
import * as NumberTraysApi from '../../utils/API/NumberTrays'
import './StoreList.scss'

export default function StoreList() {
  const [trays_data, setTrays_data] = useState([]);
  useEffect(() => {
    NumberTraysApi.getTrays().then(res => {
      const tray_data = res.data.data
      if (tray_data === undefined) return;
      // Change the tray_data to object with key is tray_number
      const tray_data_object = {}
      tray_data.forEach(item => {
        tray_data_object[item.tray_number] = item
      })
      // Sort the tray_data_object by key
      const tray_data_object_sorted = {}
      Object.keys(tray_data_object).sort().forEach(key => {
        tray_data_object_sorted[key] = tray_data_object[key];
      });

      setTrays_data(tray_data_object_sorted)
    }).catch(err => {
      alert(err)
    })
  }, [])

  return (
    <div
      className='store-list-page'
    >
      <div className="container">

        <StoreListTable data={trays_data} />
      </div>
    </div>
  )
}
