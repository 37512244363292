//Input number filter
export const isNumber = (value , if_false_return_what = false) => {
    if (value === '') {
        return 0
    }
    // Check if value is number
    if (!isNaN(value)) {
        return true
    }
    
    return if_false_return_what
}
