import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import './BlinkingUnderLineNumber.scss'

export default function BlinkingUnderLineNumber({ value, text = '', blink_value_threshold = 1, color = 'black', note = '', blink_type = '>', blink_note = '' }) {

    const blink_components = (
        <div className='blinking-under-line-number'>
            <span className="number blinking"
                style={{ color: color }}
                data-html={true}
                data-tip={'<div class="font-normal">' + blink_note + '</div>'}
            >{text === '' ? value : text}</span>
        </div>
    )
    if (blink_type === '>') {
        if (value > blink_value_threshold) {
            return blink_components
        }
    } else if (blink_type === '<') {
        if (value < blink_value_threshold) {
            return blink_components
        }
    } else if (blink_type === '>=') {
        if (value >= blink_value_threshold) {
            return blink_components
        }
    } else if (blink_type === '<=') {
        if (value <= blink_value_threshold) {
            return blink_components
        }
    }

    return (
        <div className='blinking-under-line-number' >
            <div className="number"
                style={{ color: color }}
            >
                {text === '' ? value : text}
            </div>
        </div>
    );
}
