import React from 'react'
import NavBar from '../../components/navBar/NavBar'
import { redirect } from '../../globalMethods';
import './Receipt.scss'
import '../../components/ClassicTable/classicTable.scss';
export default function Receipt() {

    const inputRef = React.useRef(null);


    const order_num_submit = () => {
        const order_num = inputRef.current.value;
        redirect('/receipt/detail/' + order_num);
    }
    return (
        <div className='receipt'>
            <NavBar name='e-Receipt' clickAble={false} />
            <div className='receipt__content'>
                <div className='title'>
                    <h3 className='bold'>ระบบจัดการใบเสร็จรับเงิน สำหรับผู้ซื้อ</h3>
                </div>
                <div className="body">
                    <div className="group1">
                        <div className="title bold">
                            ช่องทาง Shopee, Lazada, ATROBOT
                        </div>
                        <div className="content">
                            <div className="item">
                                <div className="item__title">
                                    เลขที่คำสั่งซื้อ
                                </div>
                                <div className="item__input">
                                    <input type="text" className="input_text"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                order_num_submit();
                                            }
                                        }}
                                        ref={inputRef}
                                    />
                                    <i class="fa-sharp fa-solid fa-angle-right submit-btn btn-animation"
                                        onClick={() => {
                                            order_num_submit();
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group1">
                        <div className="bold">
                            ช่องทางอื่นๆ โปรดติดต่อเจ้าหน้าที่
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
