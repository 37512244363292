import axios from "axios";
import { apiUrl } from "../../config";

export function countVisitor(page_name) {
    const body = {
        page_name: page_name
    }
    const result = axios.post(apiUrl + '/stats/countVisitor', body);
    return result;
}

export function getCountVisitor(page_name) {
    const params = {
        page_name: page_name
    }
    const result = axios.get(apiUrl + '/stats/countVisitor', { params });
    return result;
}