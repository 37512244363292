import axios from 'axios'
import { apiUrl } from '../../config'
import { build_headers } from '../atrobot-api';

export function createBlog(token, title, content,coverImg) {
    const result = axios.post(apiUrl + '/blog', {
        title: title,
        content: content,
        coverImg: coverImg.source
        
    }, { headers: build_headers(token) })
    return result;
}

export function getBlogs() {
    const result = axios.get(apiUrl + '/blogs')
    return result;
}

export function getBlogDetail(blogId) {
    // params
    const params = {
        id: blogId
    }
    const result = axios.get(apiUrl + '/blog', { params: params })
    return result;
}