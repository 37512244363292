import React from 'react'
import './SelfQuotationAction.scss'

export default function SelfQuotationCreateAction(
    {
        confirmQuotation
    }
) {
    return (
        <div
            className='self-quotation-create-sub-container-self-quotation-action'
        >
            <div className="action-container">
                <button className='btn confirm-order'
                    onClick={() => confirmQuotation()}
                >>>> ขั้นตอนที่ 3 : กรอกข้อมูลใบเสนอราคา >>></button>
            </div>
        </div>
    )
}
