import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './ProductForm.scss'
import '../../../../../../styles/Icons.scss'
import { SalesListHeader } from '../../columns';
import ContentEditable from 'react-contenteditable';
import ReactTooltip from 'react-tooltip';
import * as InputUtils from '../../../../../../utils/Input'
import * as QuotationHandler from '../../../../QuotationHandler'

import { ClassicTable } from '../../../../../../components/ClassicTable/ClassicTable';
import LazyLoadSearchProductInput from '../../../../../../containers/LazyLoadSearchProductInput/LazyLoadSearchProductInput';
import { debounce } from '../../../../../../utils/TimingUtils';


const ZERO = 0;
const FORTY = 40;
const TWO_THOUSAND = 2000;

export default function ProductForm({
    selectedProductList,
    discountPrice,
    shipping_price,
    setDiscountPrice,
    setShipping_price,
}) {
    const [forceReRender, setForceReRender] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [sub_total, setSub_total] = useState(0)
    const [selectedProductShowList, setSelectedProductShowList] = useState([])

    const salesListHeader = useMemo(() => SalesListHeader, []);
    useEffect(() => {
        ReactTooltip.rebuild()
    }, [selectedProductShowList])




    // Update Sub Total Price
    useEffect(() => {

        const total = selectedProductShowList.reduce((acc, item) => {
            return acc + parseFloat(item['col7'])
        }, 0)
        const newTotal = total - parseFloat(discountPrice) + parseFloat(shipping_price)
        setSub_total(total)

    }, [selectedProductShowList])

    // Update Shipping Price
    useEffect(() => {
        if (sub_total > TWO_THOUSAND) {
            setShipping_price(ZERO)
        } else {
            setShipping_price(FORTY)
        }
    }, [sub_total])

    // Update Total Price
    useEffect(() => {
        const newTotal = sub_total - parseFloat(discountPrice) + parseFloat(shipping_price)
        setTotalPrice(newTotal)
    }, [sub_total, discountPrice, shipping_price])


    const updateShowProductList = () => {
        setSelectedProductShowList(selectedProductList.current.map((item, itemIndex) => {
            return {
                col0: (<i className="fas fa-trash delete-icon" onClick={() => deleteCartProduct(itemIndex)}></i>),
                col1: itemIndex,
                col2: item.product.Code,
                col3: <div className='custom-class-000002'><img className='product-img' src={item.img} /><span className='custom-class-000001' data-tip={'Available Stock : ' + item.product.AvailableStock}>{item.product.ProductName}</span></div>,
                col4: item.product.Variation.map(item => <button className='button-style'>{item.name}</button>),
                col5: <div className={'qty-input ' + (item.quantity > item.product.CurrentStock && 'button-style')}
                    data-tip={item.quantity > item.product.CurrentStock ? 'Not enough stock' : ''}
                ><ContentEditable
                        placeholder='0' html={String(item.quantity)} onChange={e => debouncedUpdateValueProductList(itemIndex, 'quantity', e.target.value)} />
                </div>,
                col6: String(item.price),
                col7: (item.quantity * item.price).toFixed(2),
            }
        }))
    }


    const deleteCartProduct = (index) => {
        selectedProductList.current.splice(index, 1)
        updateShowProductList()
    }


    const updateValueProductList = async (index, key, value) => {
        try {

            let isNumber = InputUtils.isNumber(value)
            // Check if value is number
            if (isNumber !== false) {
                selectedProductList.current[index][key] = value
                if (key === 'quantity') {
                    let sale_price = await QuotationHandler.getSalePrice(value, selectedProductList.current[index].product.Code)
                    // Make it to 2 decimal
                    sale_price = Math.round(sale_price * 100) / 100
                    selectedProductList.current[index]['price'] = sale_price;
                }

            }
            updateShowProductList()
        } catch (error) {
            alert(error)
        }
    }

    const debouncedUpdateValueProductList = useCallback(debounce(updateValueProductList, 1000), [])

    const discountPriceChangeHandler = (value) => {
        //  Prevent default action
        let newValue = InputUtils.isNumber(value)
        if (newValue !== false) {
            setDiscountPrice(value)
        }
        // Force reRender state
        setForceReRender(forceReRender + 1)
    }

    const addProduct = async (img, product) => {
        let sale_price = await QuotationHandler.getSalePrice(1, product.Code)
        // Add to selected product list
        selectedProductList.current.push({ product: product, quantity: 1, price: sale_price, img: img })
        updateShowProductList()
    }

    return (
        <div
            className='self-quotation-create-sub-container-product-form'
        >
            <div className="content-p2">
                <h3>ขั้นตอนที่ 2 : เลือกรายการสินค้า</h3>
                <LazyLoadSearchProductInput onSelect_product={addProduct} />
                <ReactTooltip />
                <ClassicTable data={selectedProductShowList} columns={salesListHeader} onRowClick={e => { }} />
                <div className="shipping-section">
                    <span className='shipping-label1'>ส่วนลด : </span>
                    <span className='shipping-label1'>{String(discountPrice)}</span>
                    <span className='shipping-label1'>บาท</span>
                </div>
                <div className="shipping-section">
                    <span className='shipping-label1'>ค่าจัดส่ง : </span>
                    <span className='shipping-label1'>{String(shipping_price)}</span>
                    <span className='shipping-label1'>บาท</span>
                </div>
                <div className="shipping-section">
                    <span className='shipping-label1'>ราคารวม : </span>
                    <span className='shipping-label1'>{totalPrice.toFixed(2)}</span>
                    <span className='shipping-label1'>บาท</span>
                </div>
            </div>
        </div>
    )
}
