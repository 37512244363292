import React from 'react'
import * as permission from '../../utils/permission'


export default function RolesChecker(props) {
    const userInfo = props.userInfo
    const requireRoles = props.requireRoles
    const satisfy_role = permission.is_satisfy_role(userInfo, requireRoles)
  return (
    satisfy_role && props.children
  )
}
