export const prepareReceiptData = (res, setItem, exclude_shipping = false) => {
    const itemData = [];
    let total_price = 0
    res.items.forEach(item => {
        const productCode = item.model_sku === '' ? item.item_sku : item.model_sku;
        const quantity_purchased = item.quantity_purchased;
        const original_price = item.original_price;
        const price_per_unit = Number(original_price) / Number(quantity_purchased);
        total_price += Number(original_price)
        itemData.push({
            col1: productCode,
            col2: item.item_name + ' ' + item.model_name,
            col3: quantity_purchased,
            col4: parseFloat(price_per_unit).toFixed(2),
            col5: parseFloat(original_price).toFixed(2),
        })
    })
    if (!exclude_shipping) {
        // Add shipping price
        itemData.push({
            col1: ' ',
            col2: 'ค่าจัดส่ง',
            col3: '1',
            col4: parseFloat(res.buyer_paid_shipping_fee).toFixed(2),
            col5: parseFloat(res.buyer_paid_shipping_fee).toFixed(2),
        })
    }
    else {
        res.buyer_paid_shipping_fee = 0
    }
    // Add total discount
    itemData.push({
        col1: ' ',
        col2: 'ส่วนลด',
        col3: ' ',
        col4: ' ',
        col5: parseFloat(res.seller_discount).toFixed(2)
    })
    // Add total price
    itemData.push({
        col1: ' ',
        col2: 'รวม',
        col3: ' ',
        col4: ' ',
        col5: parseFloat(total_price + Number(res.buyer_paid_shipping_fee) - Number(res.seller_discount)).toFixed(2)
    })
    setItem(itemData);
}
