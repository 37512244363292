import * as PriceAPI from '../../utils/API/PriceAPI'

export const getSalePrice = async (quantity, product_code) => {
        if (quantity === '' || quantity === undefined || quantity === null || quantity === 0) {
            quantity = 1
        }
        const response = await PriceAPI.getSalePrice(quantity, product_code)
        let sale_prices = response.data.sale_price
        
        // Make 2 decimal
        sale_prices = sale_prices.toFixed(2)
        return sale_prices
    }