import axios from "axios";
import { imageAPI } from "../../config";


export async function postImage(blobImg) {
    const data = {'image': blobImg}
    let config = {
        headers: {
            'content-type': 'application/json',
            'Authorization': String(parseInt(Math.floor(Date.now() / 1000)/3.1415926))
        }
    }
    const res = await axios.post(imageAPI + '/images', data, config);
    return res.data;
}

export async function fetchImage(url, size = 512) {
    const result = await axios.get(imageAPI + '/images',
        {
            params: {
                url: url,
                size: size
            },
            responseType: 'blob'
        })
    return result.data;
}


export const loadImage = async (url, setValue) => {
    const result = await fetchImage(url, 1024)
    var reader = new FileReader();
    reader.readAsDataURL(result);
    reader.onloadend = function (e) {
        // Append image to list
        setValue(reader);
    }
}