import React from 'react'
import NavBar from '../../components/navBar/NavBar'
import './contact.scss'

export default function Contact() {
    return (
        <div className='contact'>
            <NavBar name='Contact' />
            <div className='contact-content'>
                <span className='big-font green-color bold'>
                @ Line Official ได้เลย
                </span>
                <img src="/assets/add-friend-qr-code.png"></img>
                
                <span className='big-font bold'>
                <img className='line-icon' src="/assets/line.png"/>
                <a className='link' href="https://lin.ee/5hibbU2">https://lin.ee/5hibbU2</a>
                </span>
            </div>
        </div>
    )
}
